export const Publication_dialog_content_image_edit_layer_spring_props = {
    from: {
        opacity: 0
    },
    enter: {
        opacity: 1
    },
    leave: {
        opacity: 0
    },
    unique: true
};
